<template>
  <div>
    <div class="segmentController mt-5">
      <div class="text-center flex-grow-1   d-flex flex-column justify-center"
           v-for="(segment , i) in segments.items"
           :id="segment.key+'_segment'"
           :key="i" @click="setActiveSegment(segment.key)"
           :class="segments.selected  == segment.key && 'activeSegment'">
        {{ $t(`segments.${segment.key}`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    segments: Object,
  },
  methods: {
    setActiveSegment(val) {
      this.segments.selected = val
    },
  },
  created() {
    if (this.$route.query.step) {
      this.segments.selected = this.$route.query.step
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .segmentController {
    width: 80% !important;
  }
}

.segmentController {
  width: 35%;
  margin: 0px auto;
  border: 1px solid #47c8bd;
  height: 50px;
  border-radius: 12px;
  background: #f8f9fb;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 14px;
  color: #46b1a1;
  transition: all 0.3s;
  cursor: pointer;
}

.activeSegment {
  background: #46b1a1;
  color: white;
  box-shadow: 0 2px 3px 0 rgba(175, 175, 175, 0.46);
  border-radius: 12px;
  transition: all 0.3s;
}
</style>